<template>
  <div>
    <vs-row>
      <vs-col vs-xs="12" vs-sm="6" vs-lg="6">
        <h6 class="mb-2">Registrations</h6>
        <vx-card>
          <Registrations
            slot="no-body"
            :entity="entity"
            viewMode="compact"
          />
          <div class="text-right" slot="no-body">
            <router-link :to="{name: 'entity-registrations', params: {'id': entity.id}}">
              <vs-button type="flat">
                View All
              </vs-button>
            </router-link>
          </div>
        </vx-card>
      </vs-col>
      <vs-col vs-xs="12" vs-sm="6" vs-lg="6">
        <h6 class="mb-2">Compliances</h6>
        <vx-card>
          <Calendar
            slot="no-body"
            :entity="entity"
            hideFilters
            viewMode="compact"
            disablePagination
            :embedded_filters="{limit: 5}"
          />
          <div class="text-right" slot="no-body">
            <router-link :to="{name: 'compliancecalendar-home', params: {'id': entity.id}}">
              <vs-button type="flat">
                View All
              </vs-button>
            </router-link>
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>

import Calendar from "../Compliance/Calendar.vue"
import Registrations from "./Registrations.vue"

export default {
  props: {
    entity: {
      required: true
    }
  },
  components: {
    Calendar,
    Registrations
  },
  data() {
    return {

    }
  }
}

</script>
